<template>
  <div class="overflow-y-auto p-4">
    <h1 class="mb-2">
      {{ t("headings.document") }}
    </h1>
    <div class="card flex h-1/2 items-center justify-center p-4">
      <p v-if="waiting" class="text-xl font-bold">{{ t("labels.signing") }}</p>
      <form-button
        v-else
        :command="() => router.push('/')"
        :disabled="waiting"
        id="home-button"
        label="complete"
        textVariant="light"
        type="submit"
        variant="indigo"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import FormButton from "../components/form/FormButton.vue";
export default {
  components: { FormButton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const waiting = computed(() => {
      if (store.state.document.waiting) return true;
      return false;
    });

    onMounted(() => {
      store.dispatch("document/verifySigning", {
        authCode: route.query.code,
        state: route.query.state,
      });
    });

    return {
      router,
      t,
      waiting,
    };
  },
};
</script>
